import WardSource from "../Slide1/Layers/WardSource";
import ReactMapGL from "react-map-gl";
import { FaUser } from "react-icons/fa";
import SchoolPoints from "../Slide1/CentralPanel/MapTypes/Points";
import Choropleth from "../Slide1/CentralPanel/MapTypes/Choropleth";
import { useState,useCallback,useRef, useEffect,createContext } from "react";
import Select from 'react-select'
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select2 from "@mui/material/Select";
import SanitationChoropleth from "../Slide1/TopPanel/Legends/SanitationChoropleth";
export const myschoolsContext=createContext()
const InterventionSites=()=>{
  let interventions = [
    "All Interventions",
    "Borehole Drilling",
    "Borehole Rehabilitation",
    "Borehole Pipeline Extension",
    "Spring Development",
    "Spring Pipeline Extension"]
  let wards=["All Wards",'Ilmotiook','Siana','Mara','Koiyaki','Lolgorian','Kilgoris Central']
  
  const [hoveredFeatureId, setHoveredFeatureId] = useState(null);
  const [filters,setfilters]=useState({intervention:null,ward:null})
  const [schools,setschools]=useState([])
  const [myschools,setmyschools]=useState([])
  const [coord,setcoord]=useState([])
  const mapRef = useRef(null);
  const [theintervention,settheintervention]=useState('')
  const [theward,settheward]=useState('')
  const inputRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState([]);
  let style="mapbox://styles/mapbox/dark-v11"
  const [households,sethouseholds]=useState([])
  const [viewport, setViewport] = useState({
    latitude: -1.3367309,
    longitude: 35.3244957,
    zoom: 8.5,
    width: "100%",
    height: "100%",
  });
  const getSchools=()=>{
    fetch("/api/v1/addsiteschools", { method: "POST", credentials: "include" ,headers:{'Content-Type':'application/json'},body:JSON.stringify(filters)})
          .then((points) => points.json())
          .then((schools) => {
            console.log(schools);
            setschools(schools.schools.sort((a,b)=>b.population-a.population));
          });
  }
  const handleViewportChange = (newViewport) => {
    setViewport({ ...viewport, ...newViewport.viewState });
  };

  const moveEnd = () => {
    setViewport({
      latitude: -1.3367309,
      longitude: 35.3244957,
      zoom: 8.5,
      width: "100%",
      height: "100%",
    });
  };

  const handleHover = useCallback((event) => {
    const map = event.target;  // The map instance
    const { point } = event;   // The x, y point on the screen
  
    // Query features under the cursor at the given point
    const features = map.queryRenderedFeatures(point, {
      layers: ['vector-layer1']  // Specify the layer ID(s) you want to query
    });
  
    // If there are features at the point
    if (features.length > 0) {
      const hoveredFeature = features[0];  // Get the first feature (or handle multiple)
  
      // Check if the feature is different from the currently hovered feature
      if (hoveredFeature.id !== hoveredFeatureId) {
        // Reset the hover state of the previous feature
        if (hoveredFeatureId) {
          map.setFeatureState(
            { source: 'my-vector-source', id: hoveredFeatureId, sourceLayer: "N2S_wards-asr6x8" },
            { hover: false }
          );
        }
  
        // Set the hover state of the new feature
        setHoveredFeatureId(hoveredFeature.id);
        map.setFeatureState(
          { source: 'my-vector-source', id: hoveredFeature.id, sourceLayer: "N2S_wards-asr6x8" },
          { hover: true }
        );
      }
    } else if (hoveredFeatureId) {
      // If no feature is found but one was previously hovered, reset it
      map.setFeatureState(
        { source: 'my-vector-source', id: hoveredFeatureId, sourceLayer: "N2S_wards-asr6x8" },
        { hover: false }
      );
      setHoveredFeatureId(null);
    }
  }, [hoveredFeatureId]);
useEffect(()=>{getSchools()},[])
useEffect(()=>{
},[schools])
useEffect(()=>{
  getSchools()
},[filters])
  return (
    <div className="water-page-water-map">
      <div className="water-page-water-map-details">
        <div className="water-page-water-map-heading">
        <Select options={schools} 
        className="schools-search-select"
        ref={inputRef} 
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        styles={{fontSize:'2px'}}
        placeholder={'Search Schools'}
        />
        </div>
        <div className="water-page-households-container">
          {schools.length>0 && schools.map((schools,index)=>(
            <div className="single-household" key={index}>
            <div className="single-household-name">
            <h4>School Population: {schools.population}</h4>
            </div>
            <div className={`single-household-status`}>
                <div className="household-icon-holder">
                    <FaUser className="user-household"/>
                </div>
                <div className="household-icon-holder-opposite">
                    <h5>{schools.label || schools.properties.name}</h5>
                </div>
            </div>
        </div>
          ))
          }
        </div>
      </div>
      <div className="water-page-water-map-settings">
          <FormControl fullWidth size="small" sx={{fontFamily:'Cera Pro1',fontSize:'12px'}}>
            <InputLabel
              id="demo-simple-select-label"
              sx={{fontFamily:'Cera Pro1',fontSize:'12px'}}
            >
              Filter By Proposed Intervention
            </InputLabel>
            <Select2
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={theintervention}
              label="Filter By Proposed Intervention"
              sx={{fontFamily:'Cera Pro1',fontSize:'12px'}}
              //   onChange={handleChange}
            >
              {interventions.map((int, index) => (
                <MenuItem
                  key={index}
                  value={int}
                  sx={{fontFamily:'Cera Pro1',fontSize:'12px'}}
                  onClick={(e) => {
                    settheintervention(int);
                    setfilters({ ...filters, intervention: int });
                  }}
                >
                  {int}
                </MenuItem>
              ))}
            </Select2>
          </FormControl>
          <FormControl fullWidth size="small" sx={{fontFamily:'Cera Pro1',fontSize:'12px'}}>
            <InputLabel
              id="demo-simple-select-label"
              sx={{fontFamily:'Cera Pro1',fontSize:'12px'}}
            >
              Filter By Ward
            </InputLabel>
            <Select2
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={theward}
              label="Filter By Ward"
              sx={{fontFamily:'Cera Pro1',fontSize:'12px'}}
              //   onChange={handleChange}
            >
              {wards.map((int, index) => (
                <MenuItem
                  key={index}
                  value={int}
                  sx={{fontFamily:'Cera Pro1',fontSize:'12px'}}
                  onClick={(e) => {
                    settheward(int);
                    setfilters({ ...filters, ward: int });
                  }}
                >
                  {int}
                </MenuItem>
              ))}
            </Select2>
          </FormControl>
      </div>
        <ReactMapGL
      {...viewport}
      onMove={handleViewportChange}
    //   onMoveEnd={moveEnd}
      scrollZoom={false}
    //   dragPan={true}
      onMouseMove={handleHover}
      mapboxAccessToken={"pk.eyJ1IjoibWlzY3VpdCIsImEiOiJja3R6dXNrb2QwNHp4MnZvMXRqZGZyemN5In0.kRmmpYfd_OQ0BaAFK8u8pw"}
      mapStyle={style}
      ref={mapRef}
    >
        <WardSource/> 
        <myschoolsContext.Provider value={{myschools,setmyschools}}>
        <SchoolPoints  id={'w-s-p'} filters={filters}/>
        </myschoolsContext.Provider>
    </ReactMapGL>
    </div>

  )
}
export default InterventionSites